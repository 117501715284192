
import { defineComponent, Ref, ref } from 'vue';
import HttpRequest from '@/util/axios.config';
import { community } from '@/data';
import { ElMessageBox } from 'element-plus';
import Notice from '@/util/notice.base';
import { downloadFileHandler } from '@/util/system';
import ImportRfcardPinDialog from './components/import-rfcard-pin-dialog.vue';

export default defineComponent({
    components: {
        ImportRfcardPinDialog
    },
    setup() {
        const file: Ref<any> = ref();
        const form: Ref<any> = ref();
        const setFile = () => {
            file.value.click();
        };
        // 6.5.3 点击展示导入rfcard弹窗选择
        const isShowImportRfCardDialog = ref(false);

        const importRfCard = () => {
            isShowImportRfCardDialog.value = false;
            const formData = new FormData(form.value);
            const url = community.ProjectType.value === 'community'
                ? 'v3/web/community/key/importUserRfCardAndPin'
                : 'v3/web/office/key/importUserRfCard';
            HttpRequest.post(url, formData, () => true, [(res: {msg: string|string[]}) => {
                if (typeof res.msg === 'string') {
                    Notice.messageBox('alert', res.msg, WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => false);
                } else {
                    let str = '';

                    res.msg.forEach((text) => {
                        str += `<p>${text}</p>`;
                    });
                    console.log(str);
                    ElMessageBox.alert(
                        str,
                        WordList.TabelMessageBoxAlertRequireFailed,
                        {
                            type: 'error',
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: WordList.TabelFootSure
                        }
                    );
                }
            }, false]);
            file.value.value = '';
        };
        const download = () => {
            if (community.ProjectType.value === 'office') {
                downloadFileHandler('/download/template/People_RFCard_Template.xlsx');
            } else {
                window.open('/download/template/Resident_RFCard,PIN_Template.xlsx');
            }
        };
        return {
            file,
            form,
            setFile,
            download,
            importRfCard,
            projectType: community.ProjectType,
            isShowImportRfCardDialog
        };
    }
});
