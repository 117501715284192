import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "display-flex" }
const _hoisted_2 = { ref: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_button = _resolveComponent("cus-button")
  const _component_import_rfcard_pin_dialog = _resolveComponent("import-rfcard-pin-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (_ctx.projectType==='community')
        ? (_openBlock(), _createBlock(_component_cus_button, {
            key: 0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowImportRfCardDialog=true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$wordList.ImportRfCardAndPin), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_cus_button, {
              class: "margin-right10px",
              onClick: _ctx.download
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextDownloadCardTemplate), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_cus_button, { onClick: _ctx.setFile }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextImportRFCard), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ], 64)),
      _createVNode("form", _hoisted_2, [
        _createVNode("input", {
          type: "file",
          name: "file",
          ref: "file",
          accept: ".xlsx",
          style: {"display":"none"},
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.importRfCard && _ctx.importRfCard(...args)))
        }, null, 544)
      ], 512)
    ]),
    (_ctx.isShowImportRfCardDialog)
      ? (_openBlock(), _createBlock(_component_import_rfcard_pin_dialog, {
          key: 0,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShowImportRfCardDialog=false)),
          onImport: _ctx.setFile
        }, null, 8, ["onImport"]))
      : _createCommentVNode("", true)
  ], 64))
}